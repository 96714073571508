import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { SecondaryBtn } from './Buttons/SecondaryBtn';
import { CustomSelect, Input, Label, Thanks } from './FormComponents';
import { flexSpace, flexStart } from './layout/styles/classes';

const StyledForm = styled.form`
    padding: 32px 16px;
    max-width: ${({ theme }) => theme.sizes.md};
    box-shadow: 0px 7px 54px rgba(62, 66, 141, 0.21);
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    scroll-margin-top: 12px;

    > h4 {
        margin: 0 0 32px;
        text-align: center;
        font-size: 1.5rem;
        line-height: 175.18%;
    }

    @media (min-width: 768px) {
        padding: 24px 24px 30px;
        border-radius: 15px;
    }

    @media (min-width: 1150px) {
        > h4 {
            margin: 0 0 24px;
        }
    }
`;

const Names = styled.div`
    ${flexSpace};

    > div {
        width: 100%;

        :first-of-type {
            margin-right: 16px;
        }
    }
`;

const DateTime = styled.div`
    @media (min-width: 1150px) {
        ${flexStart};

        > div {
            :first-of-type {
                margin-right: 16px;
                width: 170px;
            }

            :last-of-type {
                flex-grow: 1;
            }
        }
    }
`;

export const AppointmentForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let data = Object.fromEntries(new FormData(e.currentTarget));

        data = {
            ...data,
            time: `${data.hour}: ${data.minutes} ${data.clock}`,
        };

        delete data.hour;
        delete data.minutes;
        delete data.clock;

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
    };

    const hourOptions = Array.from(Array(13).keys())
        .slice(1)
        .map(n => ({
            value: ('0' + n).slice(-2),
            label: ('0' + n).slice(-2),
        }));

    const minuteOptions = Array.from(Array(60).keys()).map(n => ({
        value: ('0' + n).slice(-2),
        label: ('0' + n).slice(-2),
    }));
    const clockOptions = [
        { value: 'am', label: 'am' },
        { value: 'pm', label: 'pm' },
    ];

    return (
        <StyledForm onSubmit={handleSubmit} id="appointment">
            <h4>Request an Appointment</h4>

            <Names>
                <div>
                    <Label htmlFor="fname">first name</Label>
                    <Input type="text" id="fname" name="firstName" placeholder="e.g. John" />
                </div>
                <div>
                    <Label htmlFor="lname">Last name</Label>
                    <Input type="text" id="lname" name="lastName" placeholder="e.g. Doe" />
                </div>
            </Names>
            <Label htmlFor="email">Email*</Label>
            <Input
                type="email"
                id="email"
                name="email"
                required
                placeholder="e.g: johndoe@gmail.com"
            />
            <Label htmlFor="phone">Phone Number*</Label>
            <NumberFormat
                type="tel"
                format="+1 (###) ###-####"
                customInput={Input}
                placeholder="123 456-7890"
                id="phone"
                name="phone"
                required
            />
            <DateTime>
                <div>
                    <Label htmlFor="date">Date</Label>
                    <Input
                        id="date"
                        type="date"
                        name="date"
                        css={theme => css`
                            height: 50px;
                            color: ${theme.colors.gray3};

                            :focus {
                                color: ${theme.colors.gray5};
                            }

                            @media (min-width: 1024px) {
                                height: 42px;
                            }

                            @media (min-width: 1600px) {
                                height: 46.5px;

                                ::-webkit-calendar-picker-indicator {
                                    margin-inline-start: 16px;
                                }
                            }
                        `}
                    />
                </div>
                <div>
                    <Label htmlFor="time" id="preferredTime">
                        Time
                    </Label>
                    <div
                        css={css`
                            ${flexStart};
                            > * {
                                margin-right: 8px;

                                :last-of-type {
                                    margin-right: 0;
                                }
                            }
                            > span {
                                margin-right: 8px !important;
                                margin-bottom: 16px;
                            }
                        `}
                    >
                        <CustomSelect
                            options={hourOptions}
                            id="hour"
                            name="hour"
                            placeholder="06"
                            aria-labelledby="preferredTime"
                        />
                        <span>:</span>
                        <CustomSelect
                            options={minuteOptions}
                            id="minutes"
                            name="minutes"
                            placeholder="00"
                            aria-labelledby="preferredTime"
                        />
                        <CustomSelect
                            options={clockOptions}
                            id="clock"
                            name="clock"
                            placeholder="am"
                            aria-labelledby="preferredTime"
                        />
                    </div>
                </div>
            </DateTime>

            <Label htmlFor="message">Message</Label>
            <Input
                as="textarea"
                id="message"
                name="message"
                placeholder="Write your message here"
                rows={5}
            />
            <SecondaryBtn
                css={css`
                    margin-top: 32px;
                    display: block;
                    width: 100%;

                    @media (min-width: 1150px) {
                        margin-top: 24px;
                    }
                `}
            >
                Request an Appointment
            </SecondaryBtn>
            <Thanks submit={submit}>
                <h1>Thank you</h1>
                <p>Someone will be right with you shortly!</p>
            </Thanks>
        </StyledForm>
    );
};
